import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => {
  const [MenuState, setMenuState] = React.useState(false)

  const MenuToggleFn = () => {
    setMenuState(!MenuState)
  }

  return (
    <div id="header" className="container">
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-menu">
          <div className="navbar-start">
            <Link to="/" className="navbar-item">
              <img
                src={"/images/logo.png"}
                alt="Alliance"
                style={{ maxHeight: "5rem" }}
              />
            </Link>
          </div>

          <a
            role="button"
            class={
              MenuState
                ? "navbar-burger is-large is-active"
                : "navbar-burger is-large"
            }
            onClick={e => MenuToggleFn()}
            aria-label="menu"
            aria-expanded="false"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>

          <div className={MenuState ? "navbar-end is-active" : "navbar-end"}>
            <Link
              to="/"
              className="navbar-item is-size-5 is-size-2-mobile has-text-weight-light"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="navbar-item is-size-5 is-size-2-mobile has-text-weight-light"
            >
              About
            </Link>
            <Link
              to="/companies"
              className="navbar-item is-size-5 is-size-2-mobile has-text-weight-light"
            >
              Group Companies
            </Link>
            <Link
              to="/services"
              className="navbar-item is-size-5 is-size-2-mobile has-text-weight-light"
            >
              Services
            </Link>
            <Link
              to="/news"
              className="navbar-item is-size-5 is-size-2-mobile has-text-weight-light"
            >
              News & Events
            </Link>
            <Link
              to="/contact"
              className="navbar-item is-size-5 is-size-2-mobile has-text-weight-light"
            >
              Contact
            </Link>
          </div>
        </div>
      </nav>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
