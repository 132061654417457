import { Link } from "gatsby"
import React from "react"

const Footer = () => (
  <footer
    className="footer has-background-light is-size-6"
    style={{ marginTop: "2rem", paddingTop: "4rem" }}
  >
    <div className="container">
      <div className="columns">
        <div className="column is-hidden-mobile">
          <h5 className="has-text-primary has-text-weight-bold">THE COMPANY</h5>
          <br />
          <Link to="/about" className="has-text-black ">
            ABOUT US
          </Link>
          <Link to="/about#vision" className="has-text-black ">
            VISION & MISSION
          </Link>
          <Link to="/about#quality" className="has-text-black ">
            QUALITY POLICY
          </Link>
          <Link to="/about#leadership" className="has-text-black ">
            LEADERSHIP
          </Link>
          <Link to="/services" className="has-text-black ">
            SERVICES
          </Link>
        </div>
        <div className="column is-hidden-mobile">
          <h5 className="has-text-primary has-text-weight-bold">
            GROUP COMPANIES
          </h5>
          <br />

          <Link to="/companies#alliance-india" className="has-text-black ">
            ALLIANCE WORLD MANUFACTURING LTD.
          </Link>
          <Link
            to="/companies#alliance-formulations"
            className="has-text-black "
          >
            ALLIANCE FORMULATIONS
          </Link>
          <Link to="/companies#alliance-agri-tech" className="has-text-black ">
            ALLIANCE AGRI-TECH
          </Link>
          <Link to="/companies#envair-electrodyne" className="has-text-black ">
            ENVAIR ELECTRODYNE LTD.
          </Link>
        </div>
        <div className="column is-hidden-mobile">
          <Link to="/contact" className="has-text-primary">
            CONTACT
          </Link>
          <a href="#" className="has-text-primary">
            NEWS & EVENTS
          </a>
          <Link to="/privacy" className="has-text-primary">
            PRIVACY STATEMENT
          </Link>
          <Link to="/terms" className="has-text-primary">
            TERMS & CONDITIONS
          </Link>
        </div>
        <div className="column is-4 has-text-centered">
          <p>
            ISO 9001:2015
            <br />
            Certified Company
          </p>
          <br />
          <img
            src={"/cert/gmp.png"}
            style={{
              height: "60px",
              padding: "0 0.5rem",
              objectFit: "contain",
              margin: "0 auto",
            }}
          ></img>
          <img
            src={"/cert/iso.png"}
            style={{
              height: "60px",
              padding: "0 0.5rem",
              objectFit: "contain",
              margin: "0 auto",
            }}
          ></img>
          <img
            src={"/cert/cdsco.png"}
            style={{
              height: "60px",
              padding: "0 0.5rem",
              objectFit: "contain",
              margin: "0 auto",
            }}
          ></img>
          <img
            src={"/cert/nabl.png"}
            style={{
              height: "60px",
              padding: "0 0.5rem",
              objectFit: "contain",
              margin: "0 auto",
            }}
          ></img>
        </div>
      </div>
      <hr className="has-background-primary" />
      <div className="columns">
        <div className="column">
          © {new Date().getFullYear()} ALLIANCE WORLD, INDIA. All rights
          reserved.
        </div>
        <div className="column has-text-right has-text-weight-bold"></div>
      </div>
    </div>
  </footer>
)

export default Footer
